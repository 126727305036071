#ioni-layout-sider {
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 3px 20px 1px #00000008;
  user-select: none;
  max-height: 100vh;
  overflow: auto;

  @supports (max-height: 100dvh) {
    max-height: 100dvh;
  }

  @media screen and (min-width: 992px) {
    padding-top: 68px;
    border-radius: 0 27px 27px 0;
  }

  #menu-content-wrapper {
    margin-top: 0 !important;
    height: fit-content !important;
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }

    .ant-menu-item {
      background-color: transparent !important;
      color: white !important;

      &:hover {
        background-color: transparent !important;
        color: #022140 !important;
      }

      &.ant-menu-item-selected {
        background-color: transparent !important;
        color: #022140 !important;

        a {
          color: #022140 !important;
        }

        .ant-menu-title-content {
          color: #022140 !important;
        }
      }
    }
  }

  .ant-menu {
    border: 0;
    background: transparent;
    max-height: 100vh;
    margin-bottom: 16px;

    @supports (max-height: 100dvh) {
      max-height: 100dvh;
    }

    .ant-menu-item {
      width: 270px;
      height: 44px;
      isolation: isolate;
      border-radius: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
      margin: 0;
      padding-inline: 0 !important;
      padding-left: 0 !important;
      color: white !important;

      //&:hover {
      //  background-color: white !important;
      //
      //}

      .ant-menu-title-content {
        color: white;

      }

      &.ant-menu-item-selected {
        background-color: white !important;
        color: #022140 !important;

        .ant-menu-title-content {
          color: #022140 !important;
        }
      }
    }

    .ant-menu-item-icon {
      margin-left: 4px;
      fill: #8b9ed3 !important;
    }


    .ant-menu-submenu-title {
      width: 100%;
      height: 44px;
      margin: 0;
      border-radius: 0;
    }

    .ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title {
      background-color: white !important;

      .menu-header {
        color: black !important;
      }

    }

    .ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-item-only-child {
      background-color: transparent !important;
    }
  }

  .sider-menu-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 24px;

    #app-secondary-button {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      padding: 12px 18px;
    }
  }
}

@media screen and (max-width: 590px) {
  .app-menu-container,
  .app-menu-container-no-shadow {
    display: block;
  }
}

