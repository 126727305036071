
.open-actions-button {
    display: flex !important;
    align-items: center !important;
}

.observation-list {
    display: flex;
    flex-direction: column;
    gap: 10px
}
.observation-box {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
}
.observation-box-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.observation-box-top-left {
    margin-bottom: 10px;
}
.observation-box-top-left-name {
    display: flex;
    align-items: center;
    gap: 10px;
    p {
        font-weight: 500;
        font-size: 20px;
    }
}
.dot {
    border-radius: 50px;
    width: 22px;
    height: 22px;
}
.date {
    font-size: 16px;
    color: #757575;
}

.observation-hazards-identified {
    .title {
        font-weight: 500;
        font-size: 16px;
    }
}

.observation-implemented-actions {
    margin-top: 40px;
    .title {
        font-weight: 500;
        font-size: 16px;
    }
}
