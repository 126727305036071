.zoomImage-container {
  position: relative;
  overflow: hidden;
  max-height: 800px;
  position: relative;
}

.zoomImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 12px;
}

