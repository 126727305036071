.menu-header {
  color: white !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.custom-icon {
  margin-left: 18px !important;
}

.custom-submenu-as-list {
  .custom-submenu-item {
    font-size: 14px;
    line-height: 20px;
    padding-left: 48px !important;
  }

  .ant-menu-item.ant-menu-item-only-child {
    height: 36px !important;
  }

  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: transparent;
  }

  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .custom-submenu-item {
    background: #feb500;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.ant-menu-item {
  color: white !important;
}

.ant-menu-item-selected {
  color: white !important;
}

.ant-menu-item-selected .ant-menu-item-content {
  color: white !important;
}

.ant-menu-submenu-title {
  display: flex;
  align-items: center;
  padding: 10px 16px !important;
  background-color: transparent !important;
}

.ant-menu-item-selected .ant-menu-submenu-title {
  background-color: #022140 !important;
  color: white !important;
}

.ant-menu-submenu-title .anticon {
  fill: white !important;
}

.ant-menu-item-selected .ant-menu-submenu-title .anticon {
  fill: white !important;
}

.ant-menu-submenu-title {
  background-color: transparent !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #022140 !important;
}

.custom-submenu-as-list .ant-menu-item {
  background-color: transparent !important;
}

.custom-submenu-as-list .ant-menu-item-selected {
  background-color: transparent !important;
}

