.projects-container {
  padding: 37px !important;

  .side-bar {
    display: flex;
    align-items: center;
  }

  .add-new-button {
    background-color: #022140;
  }
}

