.icons-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    flex-direction: row-reverse;
  }
}

.new-chat-icon {
  font-size: 25px;
  cursor: pointer;
  fill: white;

}

.menu-icon {
  font-size: 25px;
  cursor: pointer;
  transition: all ease .15s;
  transform: rotate(180deg);
  fill: white;
}

.open {
  transition: all ease .15s;
  transform: rotate(0deg);
}


.content {
  height: calc(100vh - 50px);
  overflow-y: auto;

  @supports (height: calc(100dvh - 50px)) {
    height: calc(100dvh - 50px);
  }
}

.hidden {
  visibility: hidden;
  pointer-events: none;
}

