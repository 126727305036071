@import '../../style/variables.less';

.ant-table-filter-dropdown {
  background-color: @opposite-primary-color !important;
}
.admin-table {
  overflow: auto;
  box-shadow: 0px 3px 20px 1px #00000008;
  background: #ffffff73;
  border-radius: 10px;
}
#invite-to-team-button {
  width: 166px;
  height: 44px;
  font-size: 14px;
  margin-right: 32px;
  border-radius: 48px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: @opposite-primary-color;
  background-color: @text-color;
  border: 0px;
}
@media screen and (max-width: 590px) {
  .admin-table {
    margin-inline: 20px;
    height: 40vh;
  }
}

