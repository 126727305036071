@import '../../../style/variables.less';

.conversation-text-feedback-history {


  .title {
    color: @text-color;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

.from{
  font-size: 18px;
  border-bottom: #0A2540 solid 1px;
  padding-bottom: 10px;
}

  .rating-icon {
    margin-right: 8px;
    font-size: 18px;

  }
}

