.pin-wrapper {
  border-radius: 100px;
  background-color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin {
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin-count{
  color: white;
  font-weight: 600
}

