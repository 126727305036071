.chat-form {
  position: relative;
  width: 100%;
  margin-top: auto;
}

.chat-form-input {
  padding: 20px 90px 20px 25px !important;
  width: 100%;
  min-height: 58px !important;
  max-height: 200px !important;
  border: none !important;
  resize: none !important;
  border-radius: 24px !important;
  box-shadow: 0 3px 20px 1px #9966ff26 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #0A2540 !important;

  &.with-attachment {
    padding-left: 48px !important;
  }

  &:focus,
  &:hover {
    border: none !important;
    outline: none !important;
  }

  &::placeholder {
    font-size: 16px;
    line-height: 20px;
    color: #0a2540b2 !important;
  }
}

.upload-file-wrapper {
  position: absolute;
  left: 15px;
  bottom: 13px;
}

.loader {
  position: absolute;
  right: 16px;
  bottom: 17px;
  font-size: 25px !important;
  cursor: pointer;
}

.action-icons-wrapper {
  position: absolute;
  right: 24px;
  bottom: 13px;
  display: flex;
  height: 34px;
  align-items: center;
}

.send-message-button {
  position: relative;
  width: 34px;
  height: 34px !important;
  background-color: #022140!important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
}

.send-message-icon {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px !important;
  cursor: pointer;
  width: 13px;
  height: 12px;
}

.realtime-wrapper {
  font-size: 25px !important;
  cursor: pointer;
  padding-inline: 8px;
}

