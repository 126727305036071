.tabItem {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dot {
  border-radius: 50px;
  width: 18px;
  height: 18px;
}

.tabItem-text {
  font-size: 16;
  font-weight: 500;
}

.count {
  font-size: 16;
  font-weight: 500;
  color: #808B95
}

.button-back {
  display: flex !important;
  align-items: center;
  gap: 10px;

  .span-arrow {
    transform: rotate(90deg)
  }
}

.tabBox {
  display: flex; 
  align-items: center;
  justify-content: space-between;
}
.observations-from {
  display: flex !important;
  align-items: center;
  gap: 10px;
}
