.agent-list {
  padding: 24px 0 !important;
  padding-left: 30px !important;

  ul {
    display: flex;
    flex-direction: column;
    gap: 19px
  }
}

.agent-item {
  color: white !important;
  font-size: 14px !important;
  line-height: 140%;
  font-weight: 500;
  padding: 0 0 0 18px !important;
}

.selected-agent {
  background: #feb500;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.conversation-list {
  ul {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}

.conversation-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  padding-inline: 8px !important;
  margin: 0 10px !important;
  transition: all 0.3s ease;
  color: white !important;

  &:hover {
    background-color: rgba(79, 81, 155, 0.7);
    border-radius: 8px;
    color: #feb500 !important;
  }
}

.selected-conversation {
  background-color: rgba(79, 81, 155, 0.7);
  color: #feb500 !important;
  padding-inline: 8px !important;
  margin: 0 10px !important;
  border-radius: 8px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  transition: all 0.3s ease;
}

