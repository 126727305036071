.action-box {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0px;
}

.action-box-button {
  display: flex ;
  align-items: center;
  gap: 10px;
}
